@import '../../../styles/vars.css';

.root {
  position: sticky;
  bottom: 0;
  z-index: 110;

  display: none;
  flex-flow: column nowrap;
  align-items: center;

  margin-top: -20px;
  margin-bottom: 40px;

  font-family: $secondaryFont;

  @media $landscapeTablet {
    margin-top: 0;
    margin-bottom: -40px;
  }
}

.container {
  width: 100%;
  max-width: $layoutWidth;
  padding-top: 20px;

  @media $landscapeTablet {
    padding: 20px;
  }
}

.root.isRendered {
  display: flex;

  visibility: hidden;
}

.isVisible.isRendered {
  visibility: visible;

  animation: slideIn 1s cubic-bezier(1, -0.4, 0.35, 0.95);
}

.panel {
  position: relative;
  z-index: 10;

  padding-bottom: 20px;

  background-color: var(--primaryColor);

  border-radius: 20px;

  transform: translateY(20px);

  @media $landscapeTablet {
    padding-bottom: 0;

    border-radius: 40px;

    transform: translateY(0);
  }
}

.panel::after {
  position: absolute;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: inherit;

  content: '';
  pointer-events: none;

  inset: 0;
}

.dismiss {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 20;

  width: 32px;
  height: 32px;
  padding: 0;

  background-color: transparent;

  border-width: 0;
  box-shadow: none;

  cursor: pointer;

  appearance: none;
  fill: transparent;

  @media $landscapeTablet {
    top: 26px;
    right: 30px;

    width: 42px;
    height: 42px;
  }
}

.dismiss svg {
  display: block;
}

.panel {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 845px auto;

  @media $landscapeTablet {
    background-position: center;
    background-size: 1321px 962px;
  }
}

.variantFirst .panel {
  background-image: url('./assets/popover-bg-mobile-00.svg');

  @media $landscapeTablet {
    background-image: url('./assets/popover-bg-desktop-00.svg');
  }
}

.variantSecond .panel {
  background-image: url('./assets/popover-bg-mobile-01.svg');

  @media $landscapeTablet {
    background-image: url('./assets/popover-bg-desktop-01.svg');
  }
}

.variantThird .panel {
  background-image: url('./assets/popover-bg-mobile-02.svg');

  @media $landscapeTablet {
    background-image: url('./assets/popover-bg-desktop-02.svg');
  }
}

.variantFourth .panel {
  background-image: url('./assets/popover-bg-mobile-03.svg');

  @media $landscapeTablet {
    background-image: url('./assets/popover-bg-desktop-03.svg');
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}
