/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable declaration-colon-newline-after */

@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  color: #000;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #e5e5e5;

  transition: opacity 0.2s;

  -webkit-tap-highlight-color: transparent;
}

.root:hover {
  @media $landscapeTablet {
    opacity: 0.95;
  }
}

.bookmark {
  position: absolute;
  right: 14px;
  bottom: 10px;
  z-index: $zIndex-1;

  opacity: 0.2;
}
