/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  align-items: center;

  max-width: 650px;
  margin: 0 auto 20px;
  padding: 15px 12px 40px;

  font-family: $secondaryFont;

  background: #f7f7f7;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

  cursor: default;

  user-select: none;

  @media $landscapeTablet {
    flex-direction: row;
    align-items: flex-start;

    padding: 20px 20px 16px;
  }
}

.cover {
  display: none;
  flex-shrink: 0;

  background: #d8d8d8;
  background-size: cover;

  border-radius: 2px;

  @media $landscapeTablet {
    display: block;

    width: 124px;
    height: 124px;
    margin-right: 20px;
  }
}

.container {
  flex-basis: auto;
  flex-grow: 1;

  width: 100%;
  min-width: 0;
  max-width: 100%;
}

.controls {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 23px;

  fill: #969696;

  @media $landscapeTablet {
    margin-top: 1px;
    margin-bottom: 36px;
  }
}

.title {
  display: none;

  padding-right: 15px;
  overflow: hidden;

  color: #6a6a6a;

  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;

  white-space: nowrap;
  text-overflow: ellipsis;

  @media $landscapeTablet {
    display: block;

    margin-bottom: 8px;
    padding-top: 5px;

    font-size: 12px;
    line-height: 16px;

    letter-spacing: 0;
  }
}

.progressText {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  width: 100%;
  margin-top: 8px;

  color: #757575;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;

  cursor: default;

  user-select: none;

  @media $landscapeTablet {
    margin-top: 6px;

    font-size: 12px;
  }
}

.button {
  position: relative;

  display: inline-block;

  width: 25px;
  margin: 0;
  padding: 0;

  color: #969696;

  background-color: transparent;
  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
  fill: currentColor;

  @media $landscapeTablet {
    height: 29px;
  }
}

.button svg {
  transform: translateZ(0);
}

.button + .button {
  margin-left: 12px;
}

.buttonPlay {
  width: 36px;
  height: 36px;

  border-radius: 50%;
  outline: none;
  box-shadow: inset 0 0 0 2px #b3b3b3;
}

.podcast {
  position: absolute;
  right: 12px;
  bottom: 13px;
  left: 12px;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;

  margin-left: auto;

  color: #757575;

  font-size: 12px;
  line-height: 1;

  @media $landscapeTablet {
    position: static;

    align-items: center;
    justify-content: flex-start;

    margin-top: 1px;

    font-size: 12px;
    line-height: 18px;
  }
}

.podcastLink {
  color: inherit;
  text-decoration: underline;

  @media $landscapeTablet {
    margin-right: 12px;
  }
}

.subscribe {
  line-height: 1;
}

.subscribe [data-type='menu'] {
  @media $landscapeTabletMax {
    right: 0;
    left: auto;
  }
}

.buttonDownload {
  width: 25px;
}

/* DYNAMIC THEME */

.isInDynamicBlock {
  margin: 26px -2px 0;
  padding: 0 0 50px;

  color: rgba(255, 255, 255, 0.5);

  background-color: transparent;
  box-shadow: none;

  @media $landscapeTablet {
    padding-bottom: 15px;
  }
}

.isInDynamicBlock .podcast {
  right: auto;
  left: 0;
}

.isInDynamicBlock .podcastLink::after {
  display: inline-block;

  padding: 0 5px;

  content: '·';
}

.isInDynamicBlock [data-type='menu'] {
  top: auto;
  right: 0;
  bottom: 26px;
  left: auto;
}

.isInEpisodeBlock {
  z-index: $zIndex-3;

  max-width: 585px;
  margin: 0;
  padding: 0;

  background-color: transparent;
  box-shadow: none;
}

.isInDynamicBlock .controls,
.isInEpisodeBlock .controls {
  @media $landscapeTablet {
    margin-bottom: 21px;
  }
}

.isInDynamicBlock .cover,
.isInDynamicBlock .title,
.isInEpisodeBlock .title,
.isInEpisodeBlock .progressText span:last-child {
  display: none;
}

.isInEpisodeBlock .progressText span:first-child {
  margin-left: auto;
}

.ghost .buttonPlay {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.ghost .buttonPlay > button {
  border-color: rgba(255, 255, 255, 0.5);

  fill: #fff;
}

.ghost .button,
.ghost .podcastLink,
.ghost .subscribe,
.ghost .progressText {
  color: rgba(255, 255, 255, 0.5);
}

.ghost [data-type='menu'] ul {
  box-shadow: none;
}

/* INSTANT */
.isInInstantPlayer {
  max-width: none;
  margin: 39px 0 0;
  padding: 0 0 11px;

  background-color: transparent;
  box-shadow: none;

  @media $landscapeTablet {
    margin: 0;
    padding-bottom: 0;
  }
}

.isInInstantPlayer .podcast {
  right: 0;
  left: 0;
}

.isInInstantPlayer [data-type='menu'] {
  top: auto;
  right: 0;
  bottom: 26px;
  left: auto;
}

.isInInstantPlayer .title {
  display: none;

  @media $landscapeTablet {
    display: block;
  }
}

.isInInstantPlayer .cover {
  display: none;

  @media $landscapeTablet {
    display: block;
  }
}

.isInInstantPlayer .controls {
  @media $landscapeTablet {
    margin-top: 0;
    margin-bottom: 12px;
  }
}
