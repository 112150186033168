@import '../../../styles/vars.css';

.root {
  position: relative;
  z-index: 10;

  display: flex;
  flex-flow: column nowrap;

  padding: 20px;
  overflow: hidden;

  color: #000;

  @media $landscapeTablet {
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 24px;
  }
}

.main {
  width: 100%;
  max-width: 650px;
}

.title {
  max-width: 432px;
  margin-bottom: 22px;
  padding-right: 44px;

  font-weight: 700;

  font-size: 12px;
  line-height: 15px;

  @media $landscapeTablet {
    max-width: 388px;
    margin-bottom: 24px;
    padding-right: 0;

    font-size: 14px;
    line-height: 17px;
  }
}

.title span {
  font-weight: normal;

  opacity: 0.5;
}

.content {

  width: 100%;
  max-width: 650px;
  margin-bottom: 20px;

  font-size: 18px;

  font-family: $primaryFont;
  line-height: 24px;

  @media $landscapeTablet {
    margin-bottom: 30px;

    font-size: 20px;
    line-height: 28px;
  }
}

.button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  height: 60px;

  color: var(--primaryColor);
  font-weight: 600;

  font-size: 14px;
  line-height: 16px;

  letter-spacing: 1px;
  text-transform: uppercase;

  background-color: #000;
  border-width: 0;
  border-radius: 30px;
}

.icon {
  margin-left: 1px;

  transform: translateY(-4px);
}

.button:hover {
  opacity: 0.8;
}
