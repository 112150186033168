@import '../../../styles/vars.css';

.root {
  color: #e0e0e0;
}

.control {
  margin: 0;
  padding: 5px 3px;

  color: inherit;

  background-color: transparent;
  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
  fill: transparent;
}

.dark {
  color: #fff;
}

.light {
  color: #000;
}

.isInBookmarks .control {
  fill: #e0e0e0;
}

.isInBookmarks.dark .control {
  fill: #fff;
}

.isInBookmarks.light .control {
  fill: #000;
}

.isInGrid .control svg {
  @media $landscapeTablet {
    width: 12px;
  }
}
