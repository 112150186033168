@import 'vars.css';

html {
  font-size: 16px;

  @media $landscapeTablet {
    font-size: calc(100vw / 66.75);
  }

  @media (min-width: $layoutWidth) {
    font-size: 20px;
  }
}

body {
  font-family: $primaryFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: 83.4375em) {
    background-color: #000;
  }
}

[data-lightbox='true'],
[data-chapter='true'] {
  overflow: hidden;
}

[data-chapter='true'] header {
  z-index: $zIndex-1;
}

/* stylelint-disable scss/at-rule-no-unknown */
@global-import '@meduza/ui-kit-2/dist/ui-kit.css';
