/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
}

.control {
  position: relative;
  z-index: $zIndex-1;

  display: block;

  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;

  background-color: transparent;

  border-width: 0;
  border-radius: 2px;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.1);

  cursor: pointer;

  appearance: none;
}

.control::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background-color: rgba(0, 0, 0, 0.4);

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  content: '';
}

.control img {
  display: block;

  max-width: 100%;

  border-radius: 2px;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.15);
}

.animation {
  position: absolute;
  top: 0;
  z-index: 2;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 50px;
  height: 50px;
  margin: auto;
  padding: 0 16px;
}

@keyframes scale-up-center {
  0% {
    height: 4px;
  }

  50% {
    height: 20px;
  }

  100% {
    height: 4px;
  }
}

.line {
  width: 2px;
  height: 20px;

  background-color: #fff;

  border-radius: 4px;

  animation: scale-up-center 1s cubic-bezier(0.39, 0.575, 0.565, 1) both
    infinite;
}

.line:nth-child(1) {
  animation-delay: 0.2s;
}

.line:nth-child(2) {
  animation-delay: 0.6s;
}

.line:nth-child(3) {
  animation-delay: 0.1s;
}

.line:nth-child(4) {
  animation-delay: 0.7s;
}

.line:nth-child(5) {
  animation-delay: 0.5s;
}

.player {
  position: relative;
  z-index: $zIndex-2;

  padding: 30px 20px 0;

  color: #000;
  text-align: center;

  background-color: #fff;
  border-radius: 8px 8px 0 0;
  transform: translateY(100%);

  transition: transform 350ms ease;

  pointer-events: none;

  @media $landscapeTablet {
    padding: 20px;

    border-radius: 8px;
  }
}

.isVisible.container {

  background-color: rgba(0, 0, 0, 0.6);
  visibility: visible;

  pointer-events: initial;

  @media $landscapeTablet {
    background-color: transparent;
  }
}

.isVisible .player {
  transform: translateY(0);

  pointer-events: initial;

  @media $landscapeTablet {
    transform: translateY(-15px);
  }
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  padding-bottom: 34px;

  color: #757575;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;

  @media $landscapeTablet {
    position: absolute;
    right: 20px;
    bottom: -15px;
    left: 164px;
    z-index: 10;
  }
}

.footer a {

  color: inherit;
  text-decoration: underline;
}

.button {
  padding: 0;

  color: #757575;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;
  text-decoration: underline;

  background-color: transparent;
  border-width: 0;
  border-radius: 0;

  cursor: pointer;

  appearance: none;
}

.image {
  width: 110px;
  height: 110px;
  margin: 0 auto 28px;

  @media $landscapeTablet {
    display: none;
  }
}

.image img {
  display: block;

  width: 100%;
  height: 100%;
  overflow: hidden;

  border-radius: 2px;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.15);
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex-1;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  visibility: hidden;

  transition: visibility 350ms ease;

  pointer-events: none;

  @media $landscapeTablet {
    width: 580px;
    margin: 0 16px 0 auto;

    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.25));
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media $landscapeTablet {
    display: none;
  }
}

.background {
  top: 0;
  left: 0;
  z-index: -1;

  display: block;
  overflow: hidden;

  color: #f7f7f7;

  border-radius: 8px 8px 0 0;

  @media $landscapeTablet {
    display: none;
  }
}

.dismiss {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;

  width: 26px;
  height: 26px;
  padding: 0;

  background-color: #ebebeb;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 1 10 10M11 1 1 11' stroke='%23757575' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;

  border-width: 0;
  border-radius: 13px;
  box-shadow: none;

  cursor: pointer;

  appearance: none;

  @media $landscapeTablet {
    top: 10px;
    right: 3px;
    left: auto;
    z-index: 10;

    width: 50px;
    height: 36px;

    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' style='pointer-events:none'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' vector-effect='non-scaling-stroke' d='m2 2 14 14m0-14L2 16'/%3E%3C/svg%3E");
    transform: none;

    cursor: pointer;

    opacity: 0.4;
  }
}

.blocks {
  max-width: 490px;
  margin: 0 auto;

  @media $landscapeTablet {
    display: none;
  }
}
