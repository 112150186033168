/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: 20px 20px 23px;

  background-color: #fff;

  border-radius: 10px;

  @media $portraitTablet {
    padding: 45px 15px 38px;
  }
}

.hasCover {
  padding-top: 0;

  @media $mobile {
    padding-top: 20px;
  }

  @media $portraitTablet {
    padding-top: 45px;
  }
}

.hasMobileCover .bookmark {
  bottom: 12px;
}

.hasMobileCover .bookmark svg {
  width: 16px;
  height: 19px;
}

.simple {
  @media $portraitTablet {
    padding-top: 60px;
  }
}

.container {
  width: 100%;
}

.head {
  display: flex;
  flex-flow: column nowrap;

  max-width: 650px;
  margin: 0 auto 20px;

  @media $landscapeTablet {
    flex-flow: row nowrap;

    max-width: 1060px;
  }
}

.cover {
  flex-grow: 1;

  margin: 18px -20px 0;

  @media $mobile {
    margin: 25px 0 0;
  }

  @media $landscapeTablet {
    order: -1;

    width: 50%;
    margin: 0;
  }
}

.materialHeader {
  position: relative;

  @media $landscapeTablet {
    max-width: 840px;
  }
}

.article {
  position: relative;
}

.simple .container {
  max-width: 1060px;
}

.simple .article {
  margin-right: auto;
  margin-left: auto;

  @media $landscapeTablet {
    margin-right: 0;
    margin-left: 0;
  }
}

.default .article {
  width: 100%;
  max-width: 650px;
}

.default .media {
  margin-right: auto;
  margin-bottom: 25px;
  margin-left: auto;
}

.default .body {
  max-width: 650px;
  margin: 0 auto;

  @media $landscapeTablet {
    max-width: 1060px;
  }
}

.center .head {
  @media $portraitTablet {
    justify-content: center;

    max-width: 860px;
  }
}

.center .materialHeader {
  @media $portraitTablet {
    align-items: center;

    text-align: center;
  }
}

.halfCover .head {
  @media $portraitTablet {
    max-width: 1005px;
  }
}

.halfCover .materialHeader {
  @media $landscapeTablet {
    width: 100%;
    max-width: none;
  }
}

.aside {
  display: none;
  flex-shrink: 0;

  width: 322px;
  margin-left: 30px;

  @media $landscapeTablet {
    display: block;
  }
}

.hasCover .aside {
  @media $landscapeTablet {
    margin-top: 0;
  }
}

.default .body {
  @media $landscapeTablet {
    display: flex;
    flex-flow: row nowrap;
  }
}

.dark {
  color: #fff;

  background-color: #1a1a1a;
  box-shadow: 0 0 0 1px #272727;
}

.dark .title {
  color: #fff;
}

.dark .tag {
  color: #b88b59;
}

.banner {
  padding-top: 12px;

  background-color: #f2f2f2;

  @media $mobile {
    display: none;
  }
}

.podcastCover {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  height: 250px;
  margin: -22px -20px 20px;
  overflow: hidden;

  border-radius: 8px 8px 0 0;

  @media $mobile {
    display: none;
  }
}

.picture .head {
  margin-bottom: 12px;

  @media $portraitTablet {
    margin-bottom: 20px;
  }
}

.bookmark {
  position: absolute;
  right: -6px;
  bottom: -4px;
  z-index: 10;

  @media $mobile {
    display: none;
  }
}

.share {
  display: flex;
  flex-flow: row nowrap;

  margin-top: 12px;

  @media $mobile {
    margin-top: 15px;
  }
}

.center .share {
  @media $mobile {
    justify-content: center;
  }
}
