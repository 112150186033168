/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: $zIndex-1;

  padding-top: 48px;

  color: #fff;

  text-align: center;

  background-color: #000;
}

.body {
  position: relative;
  z-index: $zIndex-4;

  padding: 0 12px;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background-position: center;
  background-size: cover;

  transform: scale(1.5);
  transform-origin: center;

  opacity: 0.48;
}

.image {
  position: relative;
  right: 0;
  left: 0;
  z-index: $zIndex-1;

  width: 180px;
  height: 180px;
  margin: 0 auto 36px;

  overflow: hidden;

  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  border-radius: 6px;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.42);
}

.hiddenLink::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex-2;

  content: '';
}

.root h1 {
  font-size: 19px;
  line-height: 23px;
}

.ambiance {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.gradientBackground {
  position: absolute;
  top: -20px;
  right: 0;
  left: 0;
  z-index: 10;

  height: 519px;
}

.withAmbiance {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  min-height: 560px;
  padding-top: 90px;
}

.withAmbiance .image {
  width: 109px;
  height: 109px;
  margin-bottom: 40px;
}

.withAmbiance .background {
  display: none;
}

.isInDynamicBlock {
  border-radius: inherit;
}

.isInDynamicBlock .ambiance {
  overflow: hidden;

  border-radius: inherit;
}
