/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;

  background-color: transparent;

  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
  fill: #fff;
}

.isInAudioPlayer::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: 50%;

  content: '';
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 30px;
  height: 30px;

  outline: none;

  transform: translate(-50%, -50%);
}

.isInMaterial .loader {
  @media $mobile {
    width: 24px;
    height: 24px;
  }
}

.isInAudioPlayer .loader {
  width: 26px;
  height: 26px;
}

.isInPlaylist {
  fill: #b3b3b3;
}

.loaderIcon {
  transform-origin: center;

  animation: rotate 2s linear infinite;
}

.loaderDash {

  opacity: 0.4;

  animation: dash 1.5s ease-in-out infinite;

  stroke: currentColor;
  stroke-linecap: round;
}

.isInAudioPanel {
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border-color: #b3b3b3;

  fill: #000;
}

.isInEpisodeBlock {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  align-items: center;
  justify-content: center;

  width: 100%;

  cursor: pointer;
}

.control {
  outline: none;
}

.isInEpisodeBlock .control {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: 62px;
  height: 62px;

  outline: none;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 100;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 60, 100;
    stroke-dashoffset: -10;
  }

  100% {
    stroke-dasharray: 60, 100;
    stroke-dashoffset: -50;
  }
}
