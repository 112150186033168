/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  width: 100%;

  @media $landscapeTablet {
    padding: 0 15px;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  align-items: center;

  width: 100%;
  max-width: 335px;
  /* Чтобы избежать перескакивание формы вверх вниз */
  min-height: 268px;

  color: #fff;

  @media $landscapeTablet {
    align-items: start;

    max-width: none;
    /* Чтобы избежать перескакивание формы вверх вниз */
    min-height: 237px;
  }
}

.tag {
  margin-bottom: 14px;

  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;

  font-size: 12px;
  line-height: 16px;

  letter-spacing: 1px;
  text-transform: uppercase;
}

.image {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;
  margin-bottom: 14px;
  padding-top: 2px;

  @media $landscapeTablet {
    align-items: flex-start;
  }
}

.imageBeet {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  /* Отступы и размеры для правильного размещения картинки THE BEET*/
  max-width: 203px;
  height: 60px;
  height: 52px;
  margin-bottom: 14px;
  margin-bottom: 8px;
  padding-top: 2px;

  @media $landscapeTablet {
    align-items: flex-start;
    /* Отступ снизу для текста под картинкой */

    margin-bottom: 7px;
  }
}

.image svg {
  max-width: 308px;
  height: auto;
}

.title {
  margin-top: -4px;
  margin-bottom: 8px;

  font-weight: 700;

  font-size: 30px;
  line-height: 1;

  @media $landscapeTablet {
    font-size: 32px;
  }
}

.text {
  font-size: 14px;
  font-family: $secondaryFont;
  line-height: 18px;
}

.textEng {
  /* Отступ перед формой */
  margin-bottom: 20px;

  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 18px;

  @media $landscapeTablet {
    margin-bottom: 11px;
  }
}

.text br {
  @media $landscapeTabletMax {
    display: none;
  }
}

.text p {
  max-width: 930px;
  margin: 0;
}

.text p + p {
  margin-top: 14px;
}

.text span {
  color: rgba(255, 255, 255, 0.4);
}

.textEng br {
  @media $landscapeTabletMax {
    display: none;
  }
}

.textEng p {
  max-width: 930px;
  margin: 0;
}

.textEng p + p {
  margin-top: 14px;
}

.textEng span {
  color: rgba(255, 255, 255, 0.4);
}

.content a {
  color: inherit;
}

.content a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.form {
  position: relative;

  display: flex;
  flex-flow: row nowrap;

  width: 100%;

  background: rgba(255, 255, 255, 0.1);

  border-radius: 8px 28px 28px 8px;
}

.field {
  width: 100%;
}

.field label {
  position: absolute;
  z-index: 1;

  visibility: hidden;

  opacity: 0;
}

.message {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;

  color: #f2614e;
  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 18px;

  transform: translateY(6px);

  @media $landscapeTablet {
    line-height: 1;

    transform: translateY(8px);
  }
}

.message a {
  text-decoration: underline;
}

.input {
  display: block;

  width: 100%;
  height: 48px;
  padding: 0;

  color: #fff;
  font-weight: 500;
  font-size: 16px;

  font-family: $secondaryFont;
  text-indent: 16px;

  background: transparent;
  border-width: 0;
  border-radius: 8px;
  outline: none;

  transition: border-color 0.1s ease-in;

  appearance: none;

  @media $landscapeTablet {
    height: 56px;

    font-size: 14px;

    text-indent: 16px;
  }
}

.input:focus {
  box-shadow: inset 0 0 0 1px #fff;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.4);

  opacity: 1;
}

.button {
  flex-shrink: 0;

  width: 72px;
  height: 48px;
  padding: 1px 1px 0 0;

  color: #fff;
  font-weight: 600;
  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 28px;
  text-transform: uppercase;

  background-color: #545454;
  border-width: 0;
  border-radius: 50px;
  box-shadow: none;

  cursor: pointer;

  appearance: none;

  @media $landscapeTablet {
    width: 169px;
    height: 56px;

    font-size: 12px;
    line-height: 16px;
  }
}

.button:disabled {
  color: rgba(0, 0, 0, 0.24);

  pointer-events: none;
}

.button:hover,
.button:active {
  background-color: #3d3d3d;
}

.buttonLabel {
  display: none;

  @media $landscapeTablet {
    display: inline;
  }
}

.buttonIcon {
  display: block;

  width: 24px;
  height: 16px;
  margin: 0 auto;

  fill: currentColor;

  @media $landscapeTablet {
    display: none;
  }
}

.buttonIcon svg {
  display: block;

  width: 100%;
  height: 100%;
}

.success {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  padding: 0 13px;

  color: #fff;

  font-size: 14px;
  line-height: 18px;

  text-align: center;

  background-color: #191919;
  border-radius: 8px;
}

.success p {
  margin: 0;
}

.success strong {
  font-weight: normal;
}

.subscribe {
  position: relative;

  width: 100%;
  margin: 21px auto 0;

  @media $landscapeTablet {
    margin-top: 25px;
  }
}

.subscribeEng {
  position: relative;

  width: 100%;
  margin: auto 0 0;
}

.invalid .input {
  box-shadow: inset 0 0 0 1px #f75a57;
}

.isSuccess .form {
  visibility: hidden;
}

/* Styles for Toggle Wrapper start */
.toggleWrapper {
  display: flex;
  flex-direction: column;

  gap: 8px;

  margin-bottom: 20px;

  @media $landscapeTablet {
    flex-direction: row;

    gap: 16px;

    margin-bottom: 14px;
  }
}
/* Styles for Toggle Wrapper end */

/* Styles for Toggle Swtich Component start */
.subscriptionToggle {
  display: flex;

  height: auto;

  font-weight: 600;
  font-size: 12px;

  font-family: 'Proxima Nova';
  font-style: normal;
  line-height: 16px;
  letter-spacing: 1px;

  text-align: center;
  text-transform: uppercase;

  background: rgba(255, 255, 255, 0.16);

  border-radius: 30px;

  @media $landscapeTablet {
    /* поднимаю наверх элемент, чтобы визуально был по центру NEWSLETTERS */
    transform: translateY(-17.5%);
  }
}

.subscriptionToggle [name='subscription'] {
  display: none;
}

.subscriptionToggle input + label {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 84px;
  padding: 4px 8px;

  color: #fff;

  background: rgba(255, 255, 255, 0);
  border-radius: 30px;

  cursor: pointer;

  opacity: 0.4;

  user-select: none;
}

.subscriptionToggle input:checked + label {
  color: #fff;

  background: #545454;
  opacity: 1;
}
/* Styles for Toggle Switch Component end */
